import React from "react"

import Layout from "../components/layout-fr"
import SEO from "../components/seo"
import Gallery from "react-grid-gallery"

const GalleryPage = () => (
  <Layout>
    <SEO title="Photos" />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-4">Photos</h1>
        </div>
      </div>
    </section>
    <div class="section" style={{ "padding-top": "1rem" }}>
      <div class="columns">
        <div class="column is-1" />
        <div class="column">
          <div style={{ textAlign: "center", width: "100%", display: "block" }}>
            <Gallery images={IMAGES} enableImageSelection={false} />
          </div>
        </div>
        <div class="column is-1" />
      </div>
    </div>
  </Layout>
)

const thumbWidth = "auto"
const thumbHeight = "auto"

const IMAGES = [
  {
    src: "/img/photos/convivio_1_l.png",
    thumbnail: "/img/photos/convivio_1_m.png",
    thumbnailWidth: thumbWidth,
    thumbnailHeight: thumbHeight,
    caption: "Campsite facilities",
    thumbnailCaption: "Campsite facilities",
  },
  {
    src: "/img/photos/convivio_2_l.png",
    thumbnail: "/img/photos/convivio_2_m.png",
    thumbnailWidth: thumbWidth,
    thumbnailHeight: thumbHeight,
    caption: "In front of campsite facilities",
    thumbnailCaption: "In front of campsite facilities",
  },
  {
    src: "/img/photos/eg_plot_1_l.png",
    thumbnail: "/img/photos/eg_plot_1_m.png",
    thumbnailWidth: thumbWidth,
    thumbnailHeight: thumbHeight,
    caption: "Campsite plot",
    thumbnailCaption: "Campsite plot",
  },
  {
    src: "/img/photos/eg_plot_2_l.png",
    thumbnail: "/img/photos/eg_plot_2_m.png",
    thumbnailWidth: thumbWidth,
    thumbnailHeight: thumbHeight,
    caption: "Campsite plot",
    thumbnailCaption: "Campsite plot",
  },
  {
    src: "/img/photos/eg_plot_3_l.png",
    thumbnail: "/img/photos/eg_plot_3_m.png",
    thumbnailWidth: thumbWidth,
    thumbnailHeight: thumbHeight,
    caption: "Campsite plot",
    thumbnailCaption: "Campsite plot",
  },
  {
    src: "/img/photos/mosteiro_1_l.png",
    thumbnail: "/img/photos/mosteiro_1_m.png",
    thumbnailWidth: thumbWidth,
    thumbnailHeight: thumbHeight,
    caption: "Alcobaça Monastery",
    thumbnailCaption: "Alcobaça Monastery",
  },
  {
    src: "/img/photos/nazare_1_l.png",
    thumbnail: "/img/photos/nazare_1_m.png",
    thumbnailWidth: thumbWidth,
    thumbnailHeight: thumbHeight,
    caption: "Nazaré beach (15km from the campsite)",
    thumbnailCaption: "Nazaré beach",
  },
  {
    src: "/img/photos/salgados_1_l.png",
    thumbnail: "/img/photos/salgados_1_m.png",
    thumbnailWidth: thumbWidth,
    thumbnailHeight: thumbHeight,
    caption: "Salgado beach (18km from the campsite)",
    thumbnailCaption: "Salgado beach",
  },
  // {
  //   src: "/img/photos/serra_eolicas_l.png",
  //   thumbnail: "/img/photos/serra_eolicas_m.png",
  //   thumbnailWidth: thumbWidth,
  //   thumbnailHeight: thumbHeight,
  //   caption: "Serra de Aire e Candeeiros",
  //   thumbnailCaption: "Serra de Aire e Candeeiros",
  // },
  // {
  //   src: "/img/photos/serra_rio_l.png",
  //   thumbnail: "/img/photos/serra_rio_m.png",
  //   thumbnailWidth: thumbWidth,
  //   thumbnailHeight: thumbHeight,
  //   caption: "Serra de Aire e Candeeiros",
  //   thumbnailCaption: "Serra de Aire e Candeeiros",
  // },
  {
    src: "/img/photos/serra_fornea_l.png",
    thumbnail: "/img/photos/serra_fornea_m.png",
    thumbnailWidth: thumbWidth,
    thumbnailHeight: thumbHeight,
    caption: "Serra de Aire e Candeeiros",
    thumbnailCaption: "Serra de Aire e Candeeiros",
  },
  {
    src: "/img/photos/serra_1_l.png",
    thumbnail: "/img/photos/serra_1_m.png",
    thumbnailWidth: thumbWidth,
    thumbnailHeight: thumbHeight,
    caption: "Serra de Aire e Candeeiros (15km from the campsite)",
    thumbnailCaption: "Serra de Aire e Candeeiros",
  },
]

export default GalleryPage
